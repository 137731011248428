import React from 'react';
import { Link } from 'react-router-dom';

function AboutUs() {
  // Team members data
  const teamMembers = [
    {
      name: 'Sarah Chen',
      role: 'CEO & Founder',
      image: '/images/team/sarah-chen.svg',
      bio: 'With 15+ years in data analytics, Sarah leads our vision for transforming businesses through data-driven solutions.'
    },
    {
      name: 'Michael Rodriguez',
      role: 'Chief Technology Officer',
      image: '/images/team/michael-rodriguez.svg',
      bio: 'Michael brings 12 years of experience in building scalable data infrastructure and BI solutions.'
    },
    {
      name: 'Emily Watson',
      role: 'Head of Analytics',
      image: '/images/team/emily-watson.svg',
      bio: 'Emily specializes in turning complex data into actionable insights for our clients.'
    }
  ];

  // Company values
  const values = [
    {
      icon: '🎯',
      title: 'Excellence',
      description: 'We strive for excellence in every solution we deliver, ensuring the highest quality standards.'
    },
    {
      icon: '🤝',
      title: 'Partnership',
      description: 'We build long-term partnerships with our clients, becoming an extension of their team.'
    },
    {
      icon: '💡',
      title: 'Innovation',
      description: 'We continuously innovate and adapt to emerging technologies and methodologies.'
    },
    {
      icon: '🔒',
      title: 'Trust',
      description: 'We maintain the highest levels of security and confidentiality with our clients\' data.'
    }
  ];

  // Timeline/Milestones
  const milestones = [
    {
      year: '2018',
      title: 'Company Founded',
      description: 'redMug Solutions was established with a vision to democratize data analytics.'
    },
    {
      year: '2019',
      title: 'First Enterprise Client',
      description: 'Partnered with a Fortune 500 company to transform their data infrastructure.'
    },
    {
      year: '2021',
      title: 'Global Expansion',
      description: 'Opened offices in Asia and Europe to serve our growing international client base.'
    },
    {
      year: '2023',
      title: 'Innovation Award',
      description: 'Recognized as Industry Leader in Data Analytics Solutions.'
    }
  ];

  return (
    <div className="page-container">
      <div className="max-w-6xl mx-auto">
        {/* Hero Section */}
        <div className="content-section text-center mb-12">
          <h1 className="text-4xl font-bold text-[var(--primary-color)] mb-6">
            About redMug Solutions
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            We're on a mission to help businesses harness the power of their data 
            through innovative solutions and expert guidance.
          </p>
        </div>

        {/* Mission & Vision */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <div className="content-section">
            <h2 className="heading-2">Our Mission</h2>
            <p className="text-gray-600">
              To empower organizations with data-driven insights that drive growth 
              and innovation, making advanced analytics accessible to businesses of all sizes.
            </p>
          </div>
          <div className="content-section">
            <h2 className="heading-2">Our Vision</h2>
            <p className="text-gray-600">
              To be the global leader in innovative data solutions, recognized for 
              transforming how businesses leverage their data assets.
            </p>
          </div>
        </div>

        {/* Company Values */}
        <div className="mb-12">
          <h2 className="heading-2 text-center mb-8">Our Values</h2>
          <div className="grid md:grid-cols-4 gap-6">
            {values.map((value, index) => (
              <div key={index} className="content-section text-center hover:shadow-lg transition-shadow">
                <div className="text-4xl mb-4">{value.icon}</div>
                <h3 className="text-xl font-semibold text-[var(--primary-color)] mb-2">
                  {value.title}
                </h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Timeline */}
        <div className="content-section mb-12">
          <h2 className="heading-2 text-center mb-8">Our Journey</h2>
          <div className="space-y-8">
            {milestones.map((milestone, index) => (
              <div key={index} className="flex items-start space-x-4">
                <div className="flex-shrink-0 w-24 pt-1">
                  <div className="text-lg font-bold text-[var(--accent-color)]">
                    {milestone.year}
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="h-full border-l-2 border-[var(--accent-color)] pl-8 pb-8">
                    <h3 className="text-xl font-semibold mb-2">{milestone.title}</h3>
                    <p className="text-gray-600">{milestone.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Team Section */}
        <div className="mb-12">
          <h2 className="heading-2 text-center mb-8">Our Leadership Team</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {teamMembers.map((member, index) => (
              <div key={index} className="content-section text-center hover:shadow-lg transition-shadow">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-32 h-32 rounded-full mx-auto mb-4 border-4 border-[var(--neutral-light)]"
                />
                <h3 className="text-xl font-semibold text-[var(--primary-color)] mb-2">
                  {member.name}
                </h3>
                <p className="text-[var(--accent-color)] mb-4">{member.role}</p>
                <p className="text-gray-600">{member.bio}</p>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="content-section bg-[var(--primary-color)] text-white text-center">
          <h2 className="text-2xl font-bold mb-4">
            Ready to Work Together?
          </h2>
          <p className="mb-6">
            Let's discuss how we can help transform your business with data-driven solutions.
          </p>
          <div className="flex justify-center gap-4">
            <Link to="/contact" className="btn-accent">
              Contact Us
            </Link>
            <Link to="/services" className="btn-outline-white">
              Our Services
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
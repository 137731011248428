const API_URL = 'https://api.redmug.co';

// Authentication API calls
export const authAPI = {
  register: async (userData) => {
    const response = await fetch(`${API_URL}/api/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userData.email,
        password: userData.password,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Registration failed');
    }

    return response.json();
  },

  login: async (credentials) => {
    const response = await fetch(`${API_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Login failed');
    }

    const data = await response.json();
    localStorage.setItem('token', data.access_token);
    return data;
  },

  getToken: () => {
    const token = localStorage.getItem('token');
    return token ? `Bearer ${token}` : null;
  },

  verifyToken: async (token) => {
    try {
      const response = await fetch(`${API_URL}/api/verify`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.ok;
    } catch {
      return false;
    }
  },

  logout: () => {
    localStorage.clear();
  }
};

// Blog API calls
export const blogAPI = {
  getPosts: async (page = 1, limit = 6) => {
    const skip = (page - 1) * limit;
    const response = await fetch(`${API_URL}/api/blog/?skip=${skip}&limit=${limit}`);
    if (!response.ok) throw new Error('Failed to fetch posts');
    return response.json();
  },

  getPost: async (id) => {
    const response = await fetch(`${API_URL}/api/blog/${id}`);
    if (!response.ok) throw new Error('Failed to fetch post');
    return response.json();
  },

  createComment: async (postId, content) => {
    const token = authAPI.getToken();
    if (!token) {
      throw new Error('Authentication required');
    }

    const response = await fetch(`${API_URL}/api/comments/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({ post_id: postId, content }),
    });
    if (!response.ok) throw new Error('Failed to create comment');
    return response.json();
  },

  getComments: async (postId) => {
    const response = await fetch(`${API_URL}/api/comments/${postId}`);
    if (!response.ok) throw new Error('Failed to fetch comments');
    return response.json();
  },
};

// Contact form API calls
export const contactAPI = {
  submit: async (formData) => {
    const response = await fetch(`${API_URL}/api/contact/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        subject: formData.subject || 'Contact Form Submission',
        message: formData.message
      }),
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.detail && Array.isArray(errorData.detail)) {
        throw new Error(errorData.detail.map(err => err.msg).join(', '));
      }
      throw new Error(errorData.detail || 'Failed to submit form');
    }
    
    return response.json();
  },

  getAll: async () => {
    const response = await fetch(`${API_URL}/api/contact/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Failed to fetch messages');
    }

    return response.json();
  },
};

export const userAPI = {
  getProfile: async () => {
    const token = authAPI.getToken();
    console.log('Token used for profile request:', token);

    if (!token) {
      throw new Error('Authentication required');
    }

    try {
      const response = await fetch(`${API_URL}/api/me`, {
        headers: {
          'Authorization': token,
        },
      });

      console.log('Profile response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to fetch profile');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Profile fetch error:', error);
      throw error;
    }
  },

  updateProfile: async (updateData) => {
    const token = authAPI.getToken();
    if (!token) {
      throw new Error('Authentication required');
    }

    try {
      const response = await fetch(`${API_URL}/api/me`, {
        method: 'PUT',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to update profile');
      }

      return response.json();
    } catch (error) {
      console.error('Profile update error:', error);
      throw error;
    }
  },
};
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Services() {
  // State for active service tab
  const [activeTab, setActiveTab] = useState('data-analysis');

  // Services data
  const services = {
    'data-analysis': {
      title: 'Data Analysis',
      icon: '/images/services/data-analysis.svg',
      description: 'Transform your raw data into actionable insights with our advanced analytics solutions.',
      features: [
        'Comprehensive data cleaning and preparation',
        'Advanced statistical analysis',
        'Predictive modeling and forecasting',
        'Custom reporting and visualization',
        'Real-time analytics capabilities'
      ],
      benefits: [
        'Make data-driven decisions with confidence',
        'Identify trends and patterns in your data',
        'Improve operational efficiency',
        'Reduce costs through data optimization'
      ],
      useCase: {
        title: 'Success Story: TechCorp Analytics Transformation',
        content: 'Helped TechCorp increase operational efficiency by 40% through advanced data analysis implementation.',
        metrics: ['40% efficiency increase', '25% cost reduction', '2x faster decision making']
      }
    },
    'bi-solutions': {
      title: 'Business Intelligence',
      icon: '/images/services/bi-solutions.svg',
      description: 'Empower your decision-making with comprehensive BI solutions and interactive dashboards.',
      features: [
        'Interactive dashboard creation',
        'Real-time data monitoring',
        'Automated reporting systems',
        'KPI tracking and analytics',
        'Data visualization tools'
      ],
      benefits: [
        'Gain real-time insights into business performance',
        'Streamline reporting processes',
        'Improve stakeholder communication',
        'Enable data-driven strategy development'
      ],
      useCase: {
        title: 'Success Story: Global Retail BI Implementation',
        content: 'Implemented BI solution for retail chain resulting in 30% improvement in inventory management.',
        metrics: ['30% inventory optimization', '45% faster reporting', '20% revenue increase']
      }
    },
    'consulting': {
      title: 'Consulting Services',
      icon: '/images/services/consulting.svg',
      description: 'Get expert guidance on implementing data-driven strategies for your business.',
      features: [
        'Strategic data consulting',
        'Implementation planning',
        'Team training and development',
        'Process optimization',
        'Technology stack assessment'
      ],
      benefits: [
        'Expert guidance for your data strategy',
        'Customized solutions for your business',
        'Knowledge transfer to your team',
        'Long-term strategic partnership'
      ],
      useCase: {
        title: 'Success Story: StartUp Scale-Up',
        content: 'Guided a startup through data infrastructure implementation, leading to successful Series B funding.',
        metrics: ['Successfully raised $10M', '3x team productivity', '60% process improvement']
      }
    }
  };

  return (
    <div className="page-container">
      <div className="max-w-6xl mx-auto">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-[var(--primary-color)] mb-4">
            Our Services
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Empowering your business with data-driven solutions tailored to your needs.
          </p>
        </div>

        {/* Service Navigation */}
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {Object.keys(services).map((serviceKey) => (
            <button
              key={serviceKey}
              onClick={() => setActiveTab(serviceKey)}
              className={`px-6 py-3 rounded-full transition-all duration-300 ${
                activeTab === serviceKey
                  ? 'bg-[var(--primary-color)] text-white shadow-lg'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {services[serviceKey].title}
            </button>
          ))}
        </div>

        {/* Active Service Content */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {/* Left Column - Service Details */}
          <div className="content-section">
            <div className="flex items-center mb-6">
              <img
                src={services[activeTab].icon}
                alt={services[activeTab].title}
                className="w-16 h-16 mr-4"
              />
              <h2 className="text-2xl font-bold text-[var(--primary-color)]">
                {services[activeTab].title}
              </h2>
            </div>
            
            <p className="text-gray-600 mb-6">
              {services[activeTab].description}
            </p>

            <h3 className="text-xl font-semibold mb-4">Key Features</h3>
            <ul className="space-y-3 mb-6">
              {services[activeTab].features.map((feature, index) => (
                <li key={index} className="flex items-center text-gray-600">
                  <svg className="w-5 h-5 mr-3 text-[var(--accent-color)]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>

            <h3 className="text-xl font-semibold mb-4">Benefits</h3>
            <ul className="space-y-3">
              {services[activeTab].benefits.map((benefit, index) => (
                <li key={index} className="flex items-center text-gray-600">
                  <svg className="w-5 h-5 mr-3 text-[var(--accent-color)]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                  {benefit}
                </li>
              ))}
            </ul>
          </div>

          {/* Right Column - Use Case */}
          <div className="content-section bg-[var(--neutral-light)]">
            <h3 className="text-xl font-semibold mb-6">
              {services[activeTab].useCase.title}
            </h3>
            <p className="text-gray-600 mb-6">
              {services[activeTab].useCase.content}
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {services[activeTab].useCase.metrics.map((metric, index) => (
                <div key={index} className="bg-white p-4 rounded-lg text-center">
                  <p className="text-[var(--accent-color)] font-bold">{metric}</p>
                </div>
              ))}
            </div>

            <div className="mt-8">
              <Link
                to="/contact"
                className="btn-primary w-full text-center"
              >
                Get Started with {services[activeTab].title}
              </Link>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="content-section bg-[var(--primary-color)] text-white text-center">
          <h2 className="text-2xl font-bold mb-4">
            Ready to Transform Your Business?
          </h2>
          <p className="mb-6">
            Let's discuss how our {services[activeTab].title} can help you achieve your goals.
          </p>
          <div className="flex justify-center gap-4">
            <Link to="/contact" className="btn-accent">
              Schedule a Consultation
            </Link>
            <Link 
              to="/about-us" 
              className="btn-accent"
            >
              Learn More About Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;